@import "tailwindcss/utilities";

table.gray-table {
  @apply bg-gray-light;
  thead td {
    @apply py-2 px-8 font-semibold border border-gray border-b-2;
  }
  tbody td {
    @apply py-2 px-8 border border-gray;
  }
}
