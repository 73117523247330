@import "tailwindcss/utilities";

.modal {
  transition: opacity 0.25s ease;
  .modal-container {
    @apply shadow-lg;
  }
  .modal-close {
    @apply block;
  }
}
body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}