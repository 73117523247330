/* app/javascript/stylesheets/application scss */;

@tailwind base;
@tailwind components;
@tailwind utilities;

.cio-design {
  @apply font-talent text-base;

  --font-size-2xs: 14px;
  --font-size-xs: 16px;
  --font-size-sm: 17px;
  --font-size-base: 19px;
  --font-size-lg: 21px;
  --font-size-xl: 24px;
  --font-size-2xl: 28px;
  --font-size-3xl: 32px;
  --font-size-4xl: 40px;
  --font-size-5xl: 46px;
  --font-size-6xl: 50px;
  --font-size-7xl: 67px;
  --font-size-8xl: 87px;

  --line-height-2xs: 18px;
  --line-height-xs: 20px;
  --line-height-sm: 22px;
  --line-height-base: 24px;
  --line-height-lg: 24px;
  --line-height-xl: 28px;
  --line-height-2xl: 32px;
  --line-height-3xl: 32px;
  --line-height-4xl: 40px;
  --line-height-5xl: 48px;
  --line-height-6xl: 52px;
  --line-height-7xl: 64px;
  --line-height-8xl: 84px;

  --gray-darkest: #1E2532;
  --gray-dark: #495163;
  --gray-medium: #9FA6BB;
  --gray-light: #F7F9FC;

  --green-darkest: #081C13;
  --green-dark: #217D47;
  --green-light: #96D0AD;
  --green-lightest: #E7F4ED;

  --orange-light: #FEEBE3;
}

/* Add any custom CSS here */

.tooltip {
  @apply absolute hidden bg-black px-8 py-2 text-white rounded;
  top:25px;
  left:50%;
  transform:translateX(-50%);
  width: max-content;
  max-width: 90vw;
}
.tooltip::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black;
  top: -8px;
  left:50%;
  transform:translateX(-50%);
}
.has-tooltip:hover .tooltip {
  @apply visible inline-block z-50;
}
.center-marker {
  counter-reset: section;
  li:before {
    counter-increment: section;
    content: counter(section) ". ";
    @apply mr-12 text-xl font-semibold self-center;
  }
}

@import "page-layout";

/* Used by carousel */
@import "swiper/swiper-bundle";

// @import "fonts";

/* Stimulus autocomplete sets a class 'active' on selected items */
.active { @apply underline; }

@layer base {
  a {
    @apply text-green hover:underline;
  }

  input::placeholder {
    @apply italic;
  }
}

/* tailwind does not yet support hiding scrollbars out of the box */
@layer utilities {
  .scrollbar-hidden {
    scrollbar-width: none;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
}

/* Swiper overrides */
.swiper-pagination-bullet-active { @apply bg-green; }
.swiper-slide { height: auto; }
// .swiper-container { @apply p-8; }
.swiper-button-prev {
  @apply hidden md:flex rounded-full text-green bg-green-lightest items-center justify-center h-10 w-10 border border-green;
  &::after {
    @apply text-base;
    content: "\2190";
  }
  &:hover {
    @apply bg-green text-white;
  }
}
.swiper-button-next {
  @apply hidden md:flex rounded-full text-green bg-green-lightest items-center justify-center h-10 w-10 border border-green;
  &::after {
    @apply text-base;
    content: "\2192";
  }
  &:hover {
    @apply bg-green text-white;
  }
}
.swiper-button-disabled {
  @apply hidden;
}

/* Course Report components */
.btn-round {
  @apply flex rounded-full text-green stroke-green bg-green-lightest items-center justify-center h-10 w-10 border border-green self-end;
}
.btn-round-dark {
  @apply flex rounded-full text-white bg-green items-center justify-center h-10 w-10 border border-green group-hover:bg-green group-hover:text-white self-end;
}

@media only screen and (max-width: 767px) {
  #best-of-header {
    background-image: url(../images/best-of-mobile-background.svg);
    background-position: bottom center;
    background-size: cover;
  }
  header[aria-expanded="true"] {
    position: relative;
  }
  trix-editor:empty:not(:focus)::before,
  input::placeholder {
    @apply text-xs
  }
}

.quick-links {
  @apply flex-grow text-center text-green p-2 rounded-sm border bg-green-lightest hover:cursor-pointer;
}
.quick-links--selected {
  @apply font-medium border-b-4 border-green
}

#flash .error {
  @apply text-red bg-red-lightest border-red border p-4;
}

.redesigned-form,
.review-form {
  input[type="checkbox"].disabled {
    @apply border-gray bg-gray-light;
  }
  input[type="radio"]:checked + div {
    @apply border border-green text-green rounded
  }
  input, select {
    @apply focus:ring-green-light focus:border-green
  }
  .rating {
    display: inline-block;
    position: relative;
    height: 1.25rem;
    line-height: 1.25rem;
    font-size: 1.25rem;
  }

  .rating label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
  }

  .disabled .rating {
    label {
      cursor: default;
    }
    .icon {
      @apply hidden;
    }
    &.error path.error {
      stroke: theme('colors.gray.light');
    }
  }

  .rating label:last-child {
    position: static;
  }

  .rating label:nth-child(1) {
    z-index: 5;
  }

  .rating label:nth-child(2) {
    z-index: 4;
  }

  .rating label:nth-child(3) {
    z-index: 3;
  }

  .rating label:nth-child(4) {
    z-index: 2;
  }

  .rating label:nth-child(5) {
    z-index: 1;
  }

  .rating label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .rating label .icon {
    float: left;
    color: transparent;
  }

  .rating:not(:hover) label input:checked ~ .icon,
  .rating:hover label:hover input ~ .icon {
    @apply text-orange;
  }

  .rating label input:focus:not(:checked) ~ .icon:last-child {
    text-shadow: 0 0 5px #09f;
  }

  label.required:after {
    content: ' *';
    @apply text-red;
  }
}

.trix-button-group {
  button.trix-button {
    @apply text-xs px-1 w-6;
  }
}
trix-toolbar {
  .trix-button-group.trix-button-group--file-tools,
  .trix-button.trix-button--icon-strike,
  .trix-button.trix-button--icon-link,
  .trix-button.trix-button--icon-heading-1,
  .trix-button.trix-button--icon-code,
  .trix-button.trix-button--icon-decrease-nesting-level,
  .trix-button.trix-button--icon-increase-nesting-level {
    @apply hidden;
  }
}

.trix-content ol {
  @apply list-decimal;
}
.trix-content ul {
  @apply list-disc;
}

.list-fix ul {
  @apply list-disc ml-8 pl-[2px];
}

form {
  span.error {
    @apply text-red text-base block;
  }
  trix-editor.error,
  input.error {
    @apply border-red;
  }
  .rating.error path.error {
    stroke: theme('colors.red.DEFAULT');
  }
}

#dotted-bg {
  background-image: url(../images/dotted-map.svg);
  background-position: bottom center;
  background-size: auto 100%;
}

.about-us-person-image-wrapper {
  max-width: 180px;
  @apply text-center;
}

.featured-cities {
  @apply hidden md:block -mt-11;
  ul {
    @apply flex items-center justify-center;
    li {
      @apply border border-green-neon px-2 py-8 lg:p-8 bg-white;
      a {
        @apply text-gray-darkest font-semibold text-lg lg:text-xl no-underline whitespace-nowrap;
      }
    }
  }
}

.filter-box-shadow {
  box-shadow: 0px 10px 40px rgba(51, 51, 51, 0.08);
}

@screen md {
  .masonry-4 {
    column-count: 4;
    column-gap: 3em;
  }
  // This class is already available in tailwind 3, we'll need to remove it when we upgrade
  .columns-2 {
    column-count: 2;
  }
  .break-avoid-col {
    p {
      -webkit-column-break-inside: avoid;
      /* Chrome, Safari, Opera */
      page-break-inside: avoid;
      /* Firefox */
      break-inside: avoid;
    }
  }
}

.city-item {
  @apply mt-4 w-full inline-block border border-gray text-gray-darkest py-4;
}
@screen md {
  .city-item {
    @apply mt-12 w-full inline-block border border-gray text-gray-darkest py-4;
  }
}
.w-full .iti.iti--allow-dropdown {
  width: 100%;
}

body {
  @apply antialiased;
}

strong {
  @apply font-semibold;
}

.w-full-outside-parent {
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
}

.litepicker .container__predefined-ranges {
  @apply py-4;
}
.litepicker[data-plugins*="ranges"] > .container__main > .container__predefined-ranges button {
  @apply px-4 inline-block;
  &:hover {
    @apply bg-green-light cursor-pointer;
  }
}

@import "modals";
@import "tables";
@import "cr-styled-content";
@import "content"; // to replace cr-styled-content when rebranding is complete

.breadcrumb li:not(:last-child)::after {
  content: '>';
  @apply mx-2;
}


.pagy-nav.pagination {
  @apply isolate inline-flex -space-x-px rounded-md shadow-sm;
}

.page.next a {
  @apply relative inline-flex items-center rounded-r-md border border-gray bg-white px-2 py-2 text-sm font-medium text-green hover:bg-gray-light focus:z-20;
}

.page.prev a {
  @apply relative inline-flex items-center rounded-l-md border border-gray bg-white px-2 py-2 text-sm font-medium text-green hover:bg-gray-light focus:z-20;
}

.page.next.disabled {
  @apply relative inline-flex items-center rounded-r-md border border-gray px-2 py-2 text-sm font-medium text-gray hover:bg-gray-light focus:z-20;
}

.page.prev.disabled {
  @apply relative inline-flex items-center rounded-l-md border border-gray px-2 py-2 text-sm font-medium text-gray hover:bg-gray-light focus:z-20;
}

.page a,
.page.gap {
  @apply bg-white border-gray text-green hover:bg-gray-light relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20;
}

.page.active {
  @apply z-10 border-green bg-green-lightest text-green relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20;
}

.text-3xs {
  font-size: 10px;
}

/* Cookie banner overrides */
#cc-main {
  .cm__desc {
    @apply leading-none md:leading-normal;
  }

  .cm__btn, .pm__btn, .pm__section-title, .pm__badge {
    @apply font-medium text-2xs;
  }

  .cm__desc {
    @apply text-[13px] md:text-xs;
  }

  /** Change font **/
  --cc-font-family: "TT Talent", sans-serif;

  /** Change button primary color to black **/
  --cc-btn-primary-bg: #217D47;
  --cc-btn-primary-border-color: #217D47;
  --cc-btn-primary-hover-bg: #444444;
  --cc-btn-primary-hover-border-color: #444444;

  /** Also make toggles the same color as the button **/
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);

  /** Make the buttons a bit rounder **/
  --cc-btn-border-radius: 10px;
}

/* intl-tel-input overrides to servce flag images via global cdn vs our own */
:root {
  --iti-path-flags-1x: url("https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/img/flags.webp");
  --iti-path-flags-2x: url("https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/img/flags@2x.webp");
  --iti-path-globe-1x: url("https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/img/globe.webp");
  --iti-path-globe-2x: url("https://cdn.jsdelivr.net/npm/intl-tel-input@25.3.0/build/img/globe@2x.webp");
}
