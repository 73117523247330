// Temporary transitional styles to ultimately replace those defined in .cr-styled-content

.content {
  @apply space-y-6;
  a {
    // @apply font-semibold;
  }
  code {
    @apply bg-gray-darkest inline-block p-8 rounded-lg text-orange font-normal mb-4;
  }
  h1 {
    @apply text-4xl mb-5 font-semibold;
  }
  h2 {
    @apply text-3xl mb-8 font-medium;
  }
  h3 {
    @apply text-2xl mb-5 font-medium;
  }
  h4 {
    @apply text-xl mb-5 font-medium;
  }
  h5 {
    @apply text-lg mb-5 font-medium;
  }
  h2, h3 {
    @apply scroll-mt-28;
  }
  img {
    @apply inline-block self-center;
  }
  ol {
    @apply list-decimal ml-8 pl-[2px];
  }
  strong {
    @apply font-semibold;
  }
  table {
    @apply border border-gray w-full;

    caption {
      @apply font-semibold;
    }
    tbody tr, thead tr {
      @apply border-b border-t border-gray;
    }
    thead tr {
      @apply border-b-2 border-gray;
    }
    th {
      @apply font-semibold;
    }
    tr {
      @apply bg-gray-light;
    }
    td, th {
      @apply p-3 px-6 text-left;
    }
  }
  ul {
    @apply list-disc ml-8 pl-[2px] space-y-2;
  }
  ul.none, ol.none {
    @apply list-none ml-0 pl-0;
  }
  ul.no-margin {
    @apply m-0;
  }
  p, div, table, ul, ol {
    // @apply mb-5 text-lg;
  }
  li > ul, li > ol {
    @apply mb-0;
  }
  table.bg-green-white {
    thead tr {
      @apply bg-white;
    }
    tbody {
      tr:nth-child(even) {
        @apply bg-white;
      }
      tr:nth-child(odd) {
        @apply bg-green-lightest;
      }
    }
  }
  div.no-mb {
    @apply mb-0;
  }
  div.flex {
    @apply md:space-x-6;
  }
  div.flex.flex-col {
    @apply md:space-x-0;
  }
  div.table-wrapper {
    @apply overflow-x-auto;
  }
  div.col-3, ul.col-3, ol.col-3 {
    @apply columns-2 md:columns-3 mb-5;
  }
  ul.sub-toc {
    @apply bg-gray-light list-none ml-0 px-1 md:px-2 mt-0 py-0 text-base;
  }
  ul.sidebar-toc {
    @apply bg-gray-light list-none ml-0 px-2 md:px-8 py-2 md:py-4 text-base overflow-y-auto max-h-[calc(100vh-8rem)];
    li {
      @apply my-1;
    }
    a {
      @apply px-4 py-1 rounded inline-block w-full hover:bg-green-lightest hover:text-green hover:no-underline text-gray-medium font-normal;
    }
    a.selected {
      @apply bg-green-lightest text-green no-underline;
    }
  }
  ul.toc {
    @apply bg-gray-light list-none ml-0 p-5 rounded-3xl;
    hr {
      @apply text-gray;
    }
    a {
      @apply text-base;
    }
    h4 {
      @apply m-0 font-normal text-base;
    }
  }
  blockquote {
    border-image: linear-gradient(to bottom, rgba(0,0,0,0) 10%,theme('colors.green.DEFAULT') 10%,theme('colors.green.DEFAULT') 90%,rgba(0,0,0,0) 90%);
    border-image-slice: 1;
    @apply border-l-4 border-l-green pl-8 ml-8 mt-2 mb-6;
  }
  blockquote, blockquote p {
    @apply text-green text-2xl;
  }
  hr {
    @apply my-8;
  }
  iframe[data-src*="youtube"] {
    @apply w-full aspect-video;
  }
  div.share-wrapper {
    @apply space-x-10 mb-0 mx-auto;
  }
  div.badge, div.badge div {
    @apply mb-0 text-base;
  }

  // Accordion component
  summary {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  summary::marker, summary::-webkit-details-marker {
    display: none;
  }
  summary::after {
    flex-shrink: 0;
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: currentColor;
    mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" /></svg>');
    mask-repeat: no-repeat;
    mask-size: contain;
    transition: transform 0.2s;
  }
  details[open] summary::after {
    transform: rotate(180deg);
  }
}

// Tiny editor body overrides
#mce_editor_body { margin: 1rem; }
