.header-1-col {
  @apply py-8 md:p-10 md:m-0 lg:px-20 lg:py-10 bg-gray-light
}

.header-2-col-with-image {
  @apply flex p-8 md:p-10 bg-gray-light justify-between;
  .header-text {
    @apply pr-6;
  }
}

.header-text {
  @apply lg:w-3/5 flex items-center;
}
.header-image {
  @apply lg:w-2/5 hidden lg:inline-flex justify-end;
}
.relative-container {
  @apply relative pl-12 pt-12 pb-16 pr-0 mr-8;
}

.header-1-col, .header-2-col-with-image {
  .header-container {
    @apply flex justify-between max-w-screen-2xl mx-auto w-full;
  }

  div.header-text {
    h1 {
      @apply text-3xl md:text-5xl lg:text-6xl font-semibold text-gray-darkest;
    }
  }
}

.body-with-left-sidebar {
  @apply max-w-screen-2xl mx-auto p-8 lg:py-10 lg:space-x-10 space-y-6 lg:space-y-0 flex flex-col lg:flex-row;

  .left-sidebar {
    @apply w-full lg:w-1/6 flex-shrink-0;
  }
  .main-column {
    @apply w-full lg:w-5/6 text-gray-dark text-sm;
    .content-header-right {
      @apply w-full flex justify-end mb-4 items-center;
    }
    .filter-text {
      @apply text-center md:text-left mb-8 text-gray-medium;
    }
    h2 {
      @apply text-2xl md:text-3xl lg:text-4xl font-semibold text-gray-darkest;
    }
  }
}

.body-1-col {
  @apply max-w-screen-2xl mx-auto p-8 lg:py-10 space-y-6;
}

.body-multi-col {
  @apply max-w-screen-2xl mx-auto p-8 lg:py-10 space-y-6 flex flex-wrap;
}

.schools-section {
  @apply w-full bg-gray-light;

  .row {
    @apply w-full mb-12 max-w-screen-2xl mx-auto md:px-10 lg:px-40 py-8 text-sm text-gray-darkest leading-6;
  }
  h2 {
    @apply text-4xl font-bold mb-4;
  }
  h4 {
    @apply text-sm mb-12;
  }

  ol {
    counter-reset: section;
    li:before {
      counter-increment: section;
      content: counter(section) ". ";
      @apply mr-1 md:mr-4 text-xl font-semibold;
    }
  }
}
